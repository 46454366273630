import React from 'react';
import {AppBar, Avatar, Button, ButtonGroup, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import RobotStatus from "../RobotStatus/RobotStatus";
import SettingsIcon from '@mui/icons-material/Settings';

import CustomLink from "../LogicalComponents/Routes/CustomLink";
import {makeStyles} from "@mui/styles";
import {GETTING_INITIAL_INFORMATION, IDLE} from "../StatusesComponets/statusConstants";
import {Logout} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {userLoginFail} from "../../redux/reducers/userReducer/actions";


const useStyles = makeStyles((theme) => ({
    appBarStyle: {height: '53%', borderRadius: '10px'},
    mainFlexContainer: {height: '100%', display: 'flex', alignItems: 'center'},
    textStyle: {
        flexBasis: '150px',
    },
    robotStatusStyle: {
        marginLeft: 'auto',
        marginRight: '50px',
        flexBasis: '280px',
    },
    settingsButton: {flexBasis: '50px'},
    languageButtons: {flexBasis: '150px'},
    adminText: {flexBasis: '70px'},
    avatar: {
        border: '3px solid #2C2C2C'
    },
    avatarContainer: {
        flexBasis: '75px'
    }
}))

function NavBar() {

    const {chosenSection, full_name} = useSelector((state) => state.cachedReducer)
    const {taskStatus, assemblyName} = useSelector(state => state.statusReducer)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        dispatch(userLoginFail())
    };

    const pathNameSection = {
        '/': 'Models',
        '/tasks': 'Tasks',
        '/assembly_plan': 'AssemblyPlan',
        '/status': 'Status',
        '/service': 'Service',
        '/settings': 'Settings',
        '/oee': 'Overall Equipment Effectiveness',
        '/archive': 'Archive',
    }
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('currentLanguage', language)
    };

    return (
        <AppBar position="static" className={classes.appBarStyle}>
            <div className={classes.mainFlexContainer}>
                  <Typography className={classes.textStyle} style={{
                    marginLeft: '46px',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    flexBasis: '353px'
                    }}>  {t(pathNameSection[chosenSection])} </Typography>

                {(![IDLE, GETTING_INITIAL_INFORMATION].includes(taskStatus) && chosenSection === '/status') &&
                   <>
                       <Typography fontSize={19}>{t('StatusInProcess')}:</Typography>
                        <Typography style={{textTransform: 'initial', marginLeft:'10px', maxWidth: '500px', wordBreak: 'break-all'}}>{assemblyName}</Typography>
                   </>
                }

                <div className={classes.robotStatusStyle}>
                    <RobotStatus/>
                </div>

                <CustomLink to={'/settings'} className={classes.settingsButton}>
                    <SettingsIcon style={{color: 'white'}}/>
                </CustomLink>
                {/*<ButtonGroup aria-label="outlined primary button group" className={classes.languageButtons}>*/}
                {/*    {['RUS', 'ENG'].map((item) => {*/}
                {/*        return <Button*/}
                {/*            key={item}*/}
                {/*            color={'secondary'}*/}
                {/*            variant={i18n.language === item ? 'contained' : 'outlined'}*/}
                {/*            onClick={() => {*/}
                {/*                changeLanguage(item);*/}
                {/*            }}*/}
                {/*        >{item}</Button>*/}
                {/*    })}*/}
                {/*</ButtonGroup>*/}
                <Typography className={classes.adminText} style={{fontSize: '14px'}}> {full_name} </Typography>
                <div className={classes.avatarContainer}>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar alt="Admin Admin" src="./assets/admin.jpg" className={classes.avatar}/>
                    </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={() => {setAnchorEl(null);}}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        {t('Logout')}
                    </MenuItem>
                </Menu>
            </div>
        </AppBar>
    );
}

export default NavBar;
