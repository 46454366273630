import React from 'react';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';


function useCreateTableData(data, isMobile) {
    const {t} = useTranslation();


    const getPosition = (number) => {
        switch (number.toString().length) {
            case 1:
                return '10px'
            case 2:
                return '7px'
            case 3:
                return '4px'
            default:
                return '9px'
        }
    }

    const getStatus = (status, progress) => {
        switch (status) {
            case 'Task':
                return <div style={{position: 'relative'}}>
                    <span style={{position: 'absolute', top: '5px', left: getPosition(progress), fontSize: '10px'}}>{progress}</span>
                    <CircularProgress variant="determinate" value={progress||2} color={'secondary'} size={25}/>
                </div>
            case 'SIMULATION_IN_PROCESS':
                return <div style={{position: 'relative'}}>
                  <CircularProgress color="success" size="18px"/>
                </div>
            case 'SIMULATION_NEW':
                return <CircleIcon style={{fontSize:'25px',color:'black'}}/>
            case 'SIMULATION_SUCCESS':
                return <CircleIcon style={{fontSize:'25px',color:'#24FCEF'}}/>
          case 'SIMULATION_ERROR':
            return <CircleIcon style={{fontSize:'25px',color:'red'}}/>
            default:
                return <></>
        }

    }

    return {
        rows: data ? data.map((item) => {
            const {planning_info} = item
            let progress = planning_info?.total_amount_of_parts || 0
            if (progress && progress !== 0) progress = Math.round((planning_info?.total_amount_of_assembled_parts || 0) * 100 / progress)

            return isMobile ?
              {
                  name: item.name,
                  assemble: `${planning_info?.total_amount_of_assembled_parts || 0} ${t('OutOf')} ${
                    planning_info?.total_amount_of_parts || 0
                  }`,
                  rotations: planning_info?.total_number_of_iterations - 1 || 0
              }
            :
              {
                progress: getStatus(item.status, progress),
                name: item.name,
                upload_time: item.upload_time,

                assemble: `${planning_info?.total_amount_of_assembled_parts || 0} ${t('OutOf')} ${
                    planning_info?.total_amount_of_parts || 0
                }`,
                rotations: planning_info?.total_number_of_iterations - 1 || 0,
                count: item?.count || 0
            }
        }) : []
    }
}

export default useCreateTableData;

