import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Snackbar} from "@mui/material";
import {closeGeneralMessage} from "../../../redux/reducers/layoutReducer/actions";


export default function MessageComponent() {
    const dispatch = useDispatch()
    const {
        messageIsShown,
        generalMessage,
        messageType
    } = useSelector((state) => state.layoutReducer);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(closeGeneralMessage())
    };

    return (

        <Snackbar open={messageIsShown} autoHideDuration={4000} onClose={handleClose}
                  anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                  }}>
            <Alert onClose={handleClose} severity={messageType}>
                {generalMessage}
            </Alert>
        </Snackbar>

    );
}
