import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralTable from "./GeneralTable";
import {getSinglePlannedTaskRequest, unselectPlannedTask} from "../../redux/reducers/readyTasksReducer/actions";
import useCreateTableData from "./useCreateTableData";

function PlannedTasksTable({isMobile}) {

  const headers = isMobile ? ['CadModelHeader', 'WillBeAssembledHeader','RotationHeader']
    : ['AssemblyCount', 'CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader']
  const {selectedPlannedTaskInd, plannedTasksList, plannedTasksAreLoading} = useSelector((state => state.readyTasksReducer))
  const dispatch = useDispatch()
  const {rows}  = useCreateTableData(plannedTasksList, isMobile, dispatch)
  const onRowClick = (model_name, ind) => {
    if(selectedPlannedTaskInd === ind){
      dispatch(unselectPlannedTask())
    }else{
      dispatch(getSinglePlannedTaskRequest(model_name, ind))
    }
  }
  return <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                       activeItem={selectedPlannedTaskInd} tableIsLoading={plannedTasksAreLoading}/>
}

export default PlannedTasksTable;
