import {call, put, takeLatest} from 'redux-saga/effects'
import {LOGIN_REQUEST} from "./types";
import {loginUserRequest} from "./requests";
import {userLoginFail, userLoginSuccess} from "./actions";
import {sendGeneralMessage} from "../layoutReducer/actions";
import i18n from "../../../i18n";


function* userLoginSaga(action) {
    try {
        const response = yield call(loginUserRequest, action.payload)
        yield put(userLoginSuccess(response.data))
    } catch (err) {
        const {response} = err
        let message = response && response.status === 401 ? response.data.detail : i18n.t('SOCKET_CONNECTION_ERROR')
        yield put(userLoginFail())
        yield put(sendGeneralMessage(message, 'error'))
    }
}

export default function* userSaga() {
    yield takeLatest(LOGIN_REQUEST, userLoginSaga)
}
