import React from 'react';
import {Box, Modal, Tab, Tabs} from "@mui/material";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Parts from "./Parts";
import {StyledModal} from "./StyledModal";
import Info from "./Info";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ height: 'calc(100% - 54px)' }}
          {...other}
      >
        {value === index && (
            <Box sx={{ height: '100%' }}>
              {children}
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function ModelInfoModal({open, handleClose, page}) {
  const {selectedTaskName, readyTasksList, selectedPlannedTaskName, plannedTasksList} = useSelector((state => state.readyTasksReducer))
  const {simulationTasks, selectedModelName} = useSelector((state => state.modelsReducer));
  let simulation = null;
  switch (page){
    case "tasks":
      if(readyTasksList){
        simulation = readyTasksList.find(item => item.name === selectedTaskName);
      }
      break
    case "planned":
      if(plannedTasksList){
        simulation = plannedTasksList.find(item => item.name === selectedPlannedTaskName);
      }
      break
    default:
      if(simulationTasks){
        simulation = simulationTasks.find(item => item.name === selectedModelName);
      }
  }
  const {t} = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModal>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'white', height: '50px'}}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:"#141414"}}>
                <Tab className="status_tab" label={t('allParts')} {...a11yProps(0)} style={value === 0 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
                <Tab className="status_tab" label={t('simulation_info')} {...a11yProps(1)} style={value === 1 ? {backgroundColor: '#404040', color:"white"} : {color:"white"}}/>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{height: 'calc(100% - 50px)'}}>
              <Parts simulation={simulation}/>
            </TabPanel>
            <TabPanel value={value} index={1} style={{height: 'calc(100% - 50px)'}}>
              <Info simulation={simulation}/>
            </TabPanel>
          </Box>
        </StyledModal>
      </Modal>
  );
}

export default ModelInfoModal;
