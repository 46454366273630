import styled from 'styled-components';

export const StyledPlanner = styled.div`
  height: 100%;
  width: 100%;
  
  .parts_table{
    border-collapse: collapse;
  }
  .parts_table td, .parts_table th{
    border: 1px solid white;
    padding: 5px 15px;
    height: 30px;
  }
  .parts_table td > * {
    vertical-align : middle;
  }
`;
