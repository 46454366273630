import styled from 'styled-components';

export const StyledDebug = styled.div`
  height: 100%;
  width: 100%;
  .main_box{
    color: white;
    height: 100%;
    background-color: rgb(20, 20, 20);
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .planner_div{
    height: 100%;
    border-right: 1px solid white;
  }
`;
