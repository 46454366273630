import React, {useContext, useEffect} from 'react';

import ProcessAction from "../../GeneralComponents/ProcessAction/ProcessAction";
import StatusScanningError from "../../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../../GeneralComponents/UnknownStatus/UnknownStatus";
import {
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR,
    EXECUTOR_SUCCESS, EXECUTOR_TIMEOUT_ERROR, IDLE,

} from "../../statusConstants";
import {useDispatch, useSelector} from "react-redux";
import StatusInfo from "../../GeneralComponents/StatusInfro/StatusInfo";
import ProcessingActionScreen from "../../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import {Button, Modal, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {checkNeedTurn} from "../../../../redux/reducers/statusReducer/actions";
import check_role from "../../../../utils/utils";
import {StyledExecutorComponent} from "./StyledExecutorComponent";


const ExecutorComponent = ({screenVersion, isMobile}) => {
    const {executorStatus} = useSelector(state => state.statusReducer)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const {state: {ws}} = useContext(SocketContext);
    const {taskId, assemblyName} = useSelector(state => state.statusReducer)
    const {username} = useSelector((state) => state.cachedReducer)
    useEffect(() =>{
        if(executorStatus === EXECUTOR_SUCCESS){
            dispatch(checkNeedTurn(taskId, handleOpen))
        }
    }, [executorStatus])

    const statusRender = (status) => {
        switch (status) {
            case IDLE:
                return <StatusInfo status={status} isMobile={isMobile}/>
            case EXECUTOR_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={status} isMobile={isMobile}/> :
                    <ProcessingActionScreen status={status} isMobile={isMobile}/>
            case EXECUTOR_ERROR:
            case EXECUTOR_TIMEOUT_ERROR:
            case EXECUTOR_ROBOT_ERROR:
                return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
            case EXECUTOR_SUCCESS:
                return <>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                        <StyledExecutorComponent>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <b>{t('assembly_ended_modal_text')}:</b>
                            </Typography>
                            {check_role(username) &&
                              <>
                                  <Button className={"success_btn"}
                                    onClick={() => {ws.emit('successfully_end_assembly', taskId, assemblyName)}}>
                                    <b>{t('succ_end_but')}</b></Button>
                                    <br/>
                                  <Button className={"unsuccess_btn"}
                                    onClick={() => {ws.emit('send_task_to_archive', taskId)}}>
                                    <b>{t('cancel_end_but')}</b></Button>
                              </>
                            }
                        </StyledExecutorComponent>
                    </Modal>
                    <StatusInfo status={status} isMobile={isMobile}/>
                    </>
            default:
                return <UnknownStatus executor={true} isMobile={isMobile}/>
        }
    }
    return statusRender(executorStatus)
};

export default ExecutorComponent;
