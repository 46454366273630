import {Button, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SET_PLANNED_TASK_REQUEST} from "../../redux/reducers/readyTasksReducer/types";


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 250,
  background: 'red',
  backgroundColor: 'white',
  borderRadius: '13px',
};

export default function ModalAddToPlan({open, handleClose, count, reloadPage}) {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [newCount, setNewCount] = React.useState(0);
  const {selectedTaskName} = useSelector((state) => state.readyTasksReducer)
  const handleClick = () => {
    dispatch({type: SET_PLANNED_TASK_REQUEST, selectedTaskName, count: parseInt(newCount), handleClose, reloadPage})
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} style={{textAlign: 'center'}}>
          <div style={{width: 'calc(100% - 20px)', margin: '0', position: 'absolute', top: '30%', transform: 'translateY(-50%)',
            textAlign: 'left', paddingLeft: '20px', paddingTop: '20px'}}>
            <Typography style={{margin: '10px'}}>{t('ModelName')}: <b>{selectedTaskName}</b></Typography>
            <Typography style={{margin: '10px'}}>{t('CurrentAssemblyCount')}: <b>{count}</b></Typography>
            <Typography style={{margin: '10px'}}>{t('AssemblyCountChangeTo')}:
              <input type="number" value={newCount} onChange={(e)=> {
                setNewCount(e.target.value)
              }} style={{height: '20px', marginLeft: '5px', width: '80px'}} />
            </Typography>
          </div>
          <div style={{width: '100%', margin: '0', position: 'absolute', bottom: '0'}}>
            <Button color="secondary" variant="contained" style={{margin: '20px'}} onClick={handleClick}>
              {t('AddToPlan')}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
