import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../redux/customReducer/SocketContext";


const RobotControlServices = ({isMobile}) => {
    const {t} = useTranslation();
    const [robotButtonsDisabled,setRobotButtonsDisabled] = useState({IRB2600:false,IRB6700:false})

    const {
        state: {ws},
    } = useContext(SocketContext);

    const robotActions = [
        'motorsOn',
        'ppToMain',
        'motorsStop',
        'parking'
    ]


    const robotNames = {
        IRB2600: {label: 'IRB 2600', ip: process.env.REACT_APP_IRB2600_IP},
        IRB6700: {label: 'IRB 6700', ip: process.env.REACT_APP_IRB6700_IP},
    }

    const unableButton = (robotName)=>{
        setTimeout(()=>{
            setRobotButtonsDisabled({...robotButtonsDisabled,[robotName]:false})
        },2000)
    }


    return (
        <div>

            {Object.entries(robotNames).map(([robot, info]) => {
                return <div style={isMobile ? {display: 'block', textAlign: 'center', padding: '10px'}
                  : {display: 'flex', justifyContent: 'center', padding: '10px 0px'}} key={robot}>
                    <Typography color={'white'} style={{margin: 'auto 0px'}}> {info.label}</Typography>
                    {robotActions.map((actionType) => {
                        return <Button color={'secondary'} variant={'contained'} key={actionType}
                                       style={isMobile ? {margin: '0px 4px'} : {marginLeft: '1%'}}
                                       disabled={robotButtonsDisabled[robot]}
                                       onClick={() => {
                                           setRobotButtonsDisabled({...robotButtonsDisabled,[robot]:true})
                                           ws.emit('set_robot_status', {ip: info.ip, action: actionType})
                                           unableButton(robot)
                                       }}
                        > {t(actionType)} </Button>
                    })}

                </div>
            })}
        </div>
    );
};

export default RobotControlServices;
