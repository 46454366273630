import {
    CONTINUE_TASK_PROCESSING_REQUEST,
    GET_PLANNED_TASKS_FAIL,
    GET_PLANNED_TASKS_REQUEST,
    GET_PLANNED_TASKS_SUCCESS,
    GET_READY_TASKS_FAIL,
    GET_READY_TASKS_REQUEST,
    GET_READY_TASKS_SUCCESS,
    GET_SINGLE_PLANNED_TASK_FAIL,
    GET_SINGLE_PLANNED_TASK_REQUEST,
    GET_SINGLE_PLANNED_TASK_SUCCESS,
    GET_SINGLE_READY_TASK_FAIL,
    GET_SINGLE_READY_TASK_REQUEST,
    GET_SINGLE_READY_TASK_SUCCESS,
    SET_LIST_OF_MISSED_PARTS, SET_LIST_OF_MISSED_PARTS_PLANNED,
    SUBMIT_TASK_PROCESSING_FAIL,
    SUBMIT_TASK_PROCESSING_REQUEST,
    SUBMIT_TASK_PROCESSING_SUCCESS,
    TASK_DELETE_MODEL_FAIL,
    TASK_DELETE_MODEL_REQUEST,
    TASK_DELETE_MODEL_SUCCESS,
    UNSELECT_PLANNED_TASK, UNSELECT_TASK,
} from "./types";
import {removeModel} from "../../../utils/helperFunctions/creatingTableData";


const initialState = {
    readyTasksList: [],
    total_pages: 0,
    plannedTasksList: [],
    readyTasksAreLoading: false,
    readyTasksListErrorOccurred: false,
    selectedTaskName: null,
    singleTaskIsLoading: false,
    selectedTask: null,
    selectedTaskInd: null,
    selectedPlannedTask: null,
    selectedPlannedTaskName: null,
    selectedPlannedTaskInd: null,
    singleTaskErrorOccurred: false,
    missedPartsList: [],
    missedPartsListPlanned: [],
    updatingStatus: false,
    updatingStatusError: false,
    processingTaskIsLoading: false,
    processingTaskErrorOccurred: false,
    submittingProcessingTask: false,
    submittingProcessingTaskErrorOccurred: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANNED_TASKS_REQUEST:
            return {...state, plannedTasksAreLoading: true}
        case GET_PLANNED_TASKS_SUCCESS:
            return {...state, plannedTasksList: action.payload,plannedTasksAreLoading: false}
        case GET_PLANNED_TASKS_FAIL:
            return {...state, plannedTasksList: [], plannedTasksListErrorOccurred: true, plannedTasksAreLoading: false}
        case GET_READY_TASKS_REQUEST:
            return {...state, readyTasksAreLoading: true}
        case GET_READY_TASKS_SUCCESS:
            let count = null
            if(action.tasks.find(item => item.name === state.selectedTaskName)){
                count = action.tasks.find(item => item.name === state.selectedTaskName).count
            }
            return {...state, readyTasksList: action.tasks, total_pages: action.total_pages, readyTasksAreLoading: false,
            selectedCount: count}
        case GET_READY_TASKS_FAIL:
            return {...state, readyTasksList: [], readyTasksListErrorOccurred: true, readyTasksAreLoading: false}
        case GET_SINGLE_READY_TASK_REQUEST:
            return {...state, selectedTaskName: action.payload, selectedCount: action.count, singleTaskIsLoading: true, selectedTask: null, selectedTaskInd: action.ind}
        case GET_SINGLE_READY_TASK_SUCCESS:
            return {...state, singleTaskIsLoading: false, selectedTask: action.payload}
        case GET_SINGLE_READY_TASK_FAIL:
            return {...state, singleTaskIsLoading: false, singleTaskErrorOccurred: false}
        case SET_LIST_OF_MISSED_PARTS:
            return {...state, missedPartsList: action.payload}
        case SET_LIST_OF_MISSED_PARTS_PLANNED:
            return {...state, missedPartsListPlanned: action.payload}
        case TASK_DELETE_MODEL_REQUEST:
            return {...state, updatingStatus: true}
        case GET_SINGLE_PLANNED_TASK_REQUEST:
            return {...state, selectedPlannedTaskName: action.payload, singlePlannedTaskIsLoading: true, selectedPlannedTask: null, selectedPlannedTaskInd: action.ind}
        case GET_SINGLE_PLANNED_TASK_SUCCESS:
            return {...state, singlePlannedTaskIsLoading: false, selectedPlannedTask: action.payload}
        case GET_SINGLE_PLANNED_TASK_FAIL:
            return {...state, singlePlannedTaskIsLoading: false, singlePlannedTaskErrorOccurred: false}
        case UNSELECT_PLANNED_TASK:
            return {...state,
                selectedPlannedTask: null,
                selectedPlannedTaskName: null,
                selectedPlannedTaskInd: null,
                missedPartsListPlanned: [],
            }
        case UNSELECT_TASK:
            return {
                ...state, updatingStatus: false,
                selectedTask: null,
                selectedTaskInd: null,
                selectedTaskName: null,
                missedPartsList: [],
            }
        case TASK_DELETE_MODEL_SUCCESS:
            return {
                ...state, updatingStatus: false,
                selectedTask: null,
                selectedTaskName: null,
                missedPartsList: [],
                readyTasksList: removeModel(state.readyTasksList, action.payload)
            }

        case TASK_DELETE_MODEL_FAIL:
            return {...state, updatingStatus: false, updatingStatusError: true}
        case SUBMIT_TASK_PROCESSING_REQUEST:
            return {...state, submittingProcessingTask: true}
        case SUBMIT_TASK_PROCESSING_SUCCESS:
            return {...state, submittingProcessingTask: false}
        case SUBMIT_TASK_PROCESSING_FAIL:
            return {...state, submittingProcessingTask: false, submittingProcessingTaskErrorOccurred: true}
        case CONTINUE_TASK_PROCESSING_REQUEST:
            return {...state, submittingProcessingTask: true}
        default:
            return state;
    }
};

export default reducer;
