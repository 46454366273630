import {call, put, select, takeLatest} from "redux-saga/effects";
import {
    getArchiveModelFail,
    getArchiveModelSuccess,
    getArchiveTasksListSuccess, getGraphDataApiRequestSuccess, getGraphExcelRequestSuccess, setMissedPartsArchiveModel,
} from "./actions";
import {getArchiveTasksListRequest, getGraphDataApiRequest, getGraphExcelApiRequest} from "./requests";
import {sendGeneralMessage} from "../layoutReducer/actions";
import {
    GET_ARCHIVE_TASKS_REQUEST,
    GET_GRAPH_DATA,
    GET_GRAPH_EXCEL,
    SELECT_ARCHIVED_TASK
} from "./types";
import i18n from "../../../i18n";
import {
    turnOnViewerLoading
} from "../modelsReducer/actions";
import {getSingleModelTask} from "../modelsReducer/requests";

const dateString2Date = (dateString) => {
    const dt = dateString.split(/\.|\s/);
    return new Date(dt.slice(0, 3).reverse().join('-') + ' ' + dt[3]);
}

const getDateTimeString = (t, date) => {
    let diffDays = Math.floor(date / 86400000); // days
    let diffHrs = Math.floor((date % 86400000) / 3600000); // hours
    let diffMins = Math.round(((date % 86400000) % 3600000) / 60000); // minutes
    let diffSecs = Math.round((((date % 86400000) % 3600000) % 60000) / 1000); // seconds

    if(diffHrs === 0){
        return diffMins + " " + t('minutes') + " " + diffSecs + " " + t('seconds');
    }
    if(diffDays === 0){
        return diffHrs + " " + t('hours') + " " + diffMins + " " + t('minutes');
    }
    return diffDays + " " + t('days') + " " + diffHrs + " " + t('hours') + " " + diffMins + " " + t('minutes');
}

const getTotalTime = (t, item) => {
    let start_time = 0;
    let end_time = 0;
    if(item['exec_time'] && item['exec_time'][0] && item['exec_time'][0]['SCANNING_BEAM'] &&
      item['exec_time'][0]['SCANNING_BEAM']['start']){
        start_time = item['exec_time'][0]['SCANNING_BEAM']['start'];
    }else{
        return 0;
    }
    let index = 0;
    if(item['exec_time'][1]){
        index = 1;
    }
    if(item['exec_time'] && item['exec_time'][index] && item['exec_time'][index]['EXECUTOR_TASK'] &&
      item['exec_time'][index]['EXECUTOR_TASK']['end']){
        end_time = item['exec_time'][index]['EXECUTOR_TASK']['end'];
    }else{
        return 0;
    }
    let start_date = dateString2Date(start_time);
    let end_date = dateString2Date(end_time);
    return getDateTimeString(t, end_date - start_date);
}

const getEfficientTime = (t, item) => {
    let effic_time = 0;
    if(!item['exec_time']){
        return effic_time;
    }
    if(item['exec_time'][0]){
      for (const [key, val] of Object.entries(item['exec_time'][0])) {
        if(key !== 'PLANNER_TASK'){
            if(val.start && val.end){
                effic_time += dateString2Date(val.end) - dateString2Date(val.start);
            }
        }
      }
    }
    if(item['exec_time'][1]){
        for (const [key, val] of Object.entries(item['exec_time'][1])) {
          if(key !== 'PLANNER_TASK'){
              if(val.start && val.end){
                  effic_time += dateString2Date(val.end) - dateString2Date(val.start);
              }
          }
        }
    }
    return getDateTimeString(t, effic_time);
}

const getStartTime = (time) => {
    let date = dateString2Date(time);
    date.setHours(date.getHours() + 6);
    return (date.getFullYear() + '-'
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + '-' + ('0' + (date.getDate())).slice(-2) + ' ' + ('0' + (date.getHours())).slice(-2) + ':'
      + ('0' + (date.getMinutes())).slice(-2) + ':' + ('0' + (date.getSeconds())).slice(-2));
}

function* getArchiveTasks(action) {
    let res = [], total_pages = 0
    try {
        const {data} = yield call(getArchiveTasksListRequest, action.filter)
        let filteredData = []
        if (data && data.tasks && data.tasks.length) {
            filteredData = data.tasks.map(item => {
                item['start_time'] = getStartTime(item['upload_time']);
                item['total_time'] = getTotalTime(action.payload, item);
                item['efficient_time'] = getEfficientTime(action.payload, item);
                return item;
            })
        }
        res = filteredData
        total_pages = data.total_pages
    } catch (e) {
        yield put(sendGeneralMessage(i18n.t('tasks_loading_error'), 'error'))
    } finally {
        yield put(getArchiveTasksListSuccess(res, total_pages))
    }
}

function* getGraphData(action){
    let res = []
    let max_graph_1 = 100, max_graph_2 = 100, max_graph_3 = 100, total_min = 0, total_weight = 0
    try {
        const {data} = yield call(getGraphDataApiRequest, action.dates)
        res = data
        max_graph_1 = data.graph_1.reduce((accumulator, current) => {
            total_min = total_min + current.y
            return accumulator.y > current.y ? accumulator : current;
        });
        max_graph_1 = max_graph_1.y + 20
        max_graph_2 = data.graph_2.reduce((accumulator, current) => {
            return accumulator.y > current.y ? accumulator : current;
        });
        max_graph_2 = max_graph_2.y + 40
        let graph_3 = []
        let temp_max = 0
        if(res.graph_2){
            graph_3 = JSON.parse(JSON.stringify(res.graph_2));
            graph_3.map((item) => {
                item.y = Math.floor(item.y*100/480)
                if(temp_max < item.y){
                    temp_max = item.y
                }
                return item
            })
        }
        res.graph_3 = graph_3
        if(temp_max > 0){
            max_graph_3 = temp_max + 20
        }
        res.weights.map((item) => {
            total_weight += item
            return item
        })
        res.avg_time = getDateTimeString(action.t, res.avg_time)
    } catch (e) {
        yield put(sendGeneralMessage(i18n.t('data_loading_error'), 'error'))
    } finally {
        yield put(getGraphDataApiRequestSuccess(res, {max_graph_1: max_graph_1, max_graph_2: max_graph_2,
            max_graph_3: max_graph_3, total_min: total_min}, action.dates, res.weights, total_weight))
    }
}

function* getGraphExcel(action){
    try {
        const {data} = yield call(getGraphExcelApiRequest, action.dates)
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx');
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        yield put(sendGeneralMessage(i18n.t('data_loading_error'), 'error'))
    } finally {
        yield put(getGraphExcelRequestSuccess())
    }
}

function* getArchiveModel(action) {
    const {simulationTasks} = yield select((state) => state.modelsReducer)
    const task = simulationTasks.find((item) => item.name === action.payload)
    yield put(turnOnViewerLoading())
    try {
        const {data} = yield call(getSingleModelTask, action.payload)
        const missedParts = task?.planning_info?.total_list_of_missed_parts ? task.planning_info.total_list_of_missed_parts : []
        yield put(getArchiveModelSuccess(data))
        yield put(setMissedPartsArchiveModel(missedParts))
    } catch (e) {
        yield put(getArchiveModelFail())
        yield put(sendGeneralMessage(i18n.t('model_loading_error'), 'error'))
    }
}

export default function* readyTasksSaga() {
    yield takeLatest(GET_ARCHIVE_TASKS_REQUEST, getArchiveTasks)
    yield takeLatest(SELECT_ARCHIVED_TASK, getArchiveModel)
    yield takeLatest(GET_GRAPH_DATA, getGraphData)
    yield takeLatest(GET_GRAPH_EXCEL, getGraphExcel)
}
