import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import {Button, CircularProgress} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useTranslation} from "react-i18next";
import {StyledExecutor} from "./StyledExecutor";
import {
  EXECUTOR_ERROR,
  EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR, EXECUTOR_SUCCESS,
  EXECUTOR_TIMEOUT_ERROR,
  IDLE
} from "../../../../components/StatusesComponets/statusConstants";


function Planner() {
  const {t} = useTranslation()
  const {executorStatus, taskId: task_id} = useSelector((state) => state.statusReducer)
  const {state: {ws},} = useContext(SocketContext);

  const statusRender = (status) => {
    switch (status) {
      case IDLE:
        return status
      case EXECUTOR_IS_RUNNING:
        return <><CircularProgress size={10} style={{color: 'white', margin:'0px 5px'}}/> {status}</>
      case EXECUTOR_ERROR:
      case EXECUTOR_TIMEOUT_ERROR:
      case EXECUTOR_ROBOT_ERROR:
        return <><ErrorOutlineIcon style={{color: 'red'}}/> {status}</>
      case EXECUTOR_SUCCESS:
        return <><CheckCircleOutlineIcon style={{color: 'green'}}/> {status}</>
      default:
        return <>Unknown status: {status}</>
    }
  }

  const actionRender = (status) => {
    switch (status) {
      case IDLE:
        return <Button color={'success'} variant={'contained'} size={"small"}
                       style={{width: '100px'}}
                       onClick={() => {
                         ws.emit('start_executor_task', task_id)
                       }}> {t('Start')}</Button>
      case EXECUTOR_IS_RUNNING:
        return <Button color={'error'} variant={'contained'} size={"small"}
                       style={{width: '100px'}}
                       onClick={() => {
                         ws.emit('cancel_task', task_id, 'EXECUTOR_TASK')
                       }}> {t('Cancel')}</Button>
      case EXECUTOR_ERROR:
      case EXECUTOR_TIMEOUT_ERROR:
      case EXECUTOR_ROBOT_ERROR:
        return <Button color={'error'} variant={'contained'} size={"small"}
                       style={{width: '100px'}}
                       onClick={() => {
                         ws.emit('start_executor_task', task_id)
                       }}> {t('MakeReScan')}</Button>
      case EXECUTOR_SUCCESS:
      default:
        return <></>
    }
  }

  return (
    <StyledExecutor>
      <div style={{padding: '10px'}}>EXECUTOR STATUS:</div>
      <hr style={{margin: 0}}/>
      <div style={{padding: '20px'}}>
        <table className={"parts_table"}>
          <tr>
            <th>Task</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
            {task_id ?
              <tr>
                <td>Executor</td>
                <td>{statusRender(executorStatus)}</td>
                <td>{actionRender(executorStatus)}</td>
              </tr>
              :
              <tr><td colSpan={3} style={{textAlign: 'center'}}>Task not found...</td></tr>
            }
        </table>
      </div>
    </StyledExecutor>
  );
}

export default Planner;
