import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {StyledTaskView} from "./StyledTaskView";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {Button, CircularProgress} from "@mui/material";
import SchemeModal from "./SchemeModal";

function SelectedTaskView() {
  const {t} = useTranslation();
  const {selectedTask, selectedModel, missedPartsList, archiveModelIsLoading} = useSelector((state => state.archiveReducer))

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getStatus = (check, str) =>{
    if(check){
      return selectedTask.statuses[str];
    }else{
      if(selectedTask['exec_time'] && selectedTask['exec_time'][1]){
        return 'SUCCESS';
      }
      return selectedTask.statuses[str] || '';
    }
  }

  const dateString2Date = (dateString) => {
    const dt = dateString.split(/\.|\s/);
    return new Date(dt.slice(0, 3).reverse().join('-') + ' ' + dt[3]);
  }

  const getTimeDiff = (task, index, retry = false) => {
    let start_time = 0;
    let end_time = 0;
    if(retry){
      if(selectedTask['exec_time'] && selectedTask['exec_time'][index] && selectedTask['exec_time'][index][task] &&
        selectedTask['exec_time'][index][task] && selectedTask['exec_time'][index][task]['retries']
          && selectedTask['exec_time'][index][task]['retries']['date']){
        start_time = selectedTask['exec_time'][index][task]['retries']['date'];
      }else{
        return 0;
      }
    }else{
      if(selectedTask['exec_time'] && selectedTask['exec_time'][index] && selectedTask['exec_time'][index][task] &&
        selectedTask['exec_time'][index][task]['start']){
        start_time = selectedTask['exec_time'][index][task]['start'];
      }else{
        return 0;
      }
    }
    if(selectedTask['exec_time'] && selectedTask['exec_time'][index] && selectedTask['exec_time'][index][task] &&
      selectedTask['exec_time'][index][task]['end']){
      end_time = selectedTask['exec_time'][index][task]['end'];
    }else{
      return 0;
    }
    let start_date = dateString2Date(start_time);
    let end_date = dateString2Date(end_time);
    let diffMs = (end_date - start_date);
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let diffSecs = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000); // seconds

    if(diffHrs === 0){
      return diffMins + " " + t('minutes') + " " + diffSecs + " " + t('seconds');
    }
    if(diffDays === 0){
      return diffHrs + " " + t('hours') + " " + diffMins + " " + t('minutes');
    }
    return diffDays + " " + t('days') + " " + diffHrs + " " + t('hours') + " " + diffMins + " " + t('minutes');
  }

  return <StyledTaskView>
      {selectedTask &&
        <>
          <div className="header"><b>{t('CadModelHeader')}:</b> {selectedTask.assembly_name}
            {selectedModel && <Button variant="contained" color="secondary" component="label" size={"small"}
                                      onClick={() => {handleOpen()}} style={{marginLeft: '10px'}}>
              {t('scheme')}
            </Button>}
            {selectedModel?.file?.weight ?
            <><br/><b>{t('weight')}:</b> {(
              selectedModel.file.weight
            ).toString().replace(',', '.') + ' ' + t('kg')}</> : ''}
          </div>
          <div className="body">
            <b>{t('AssembleStarted')}:</b> {selectedTask.start_time}<br/>
            <b>{t('EfficientTime')}:</b> {selectedTask.efficient_time}<br/>
            <b>{t('OverallAssembleTime')}:</b> {selectedTask.total_time}<br/>
            <b>{t('TurnCount')}:</b> {selectedTask.total_number_turns ? selectedTask.total_number_turns - 1 : 0}
          </div>
          <div>{t('Statuses')}:</div>
          <hr/>
          <div>
            <b>{t('BeamScan')}:</b> {getStatus(0, 'scanning_beam_status')}<br/>
            <b>{t('ProcessingTime')}:</b> {getTimeDiff('SCANNING_BEAM', 0)}<br/>
            {selectedTask.exec_time?.retries?.count &&
              <>
                <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('SCANNING_BEAM', 0, 1)}
              </>
            }
          </div><hr/>
          <div>
            <b>{t('PartsScan')}:</b> {getStatus(0, 'scanning_parts_status')}<br/>
            <b>{t('ProcessingTime')}:</b> {getTimeDiff('SCANNING_PARTS', 0)}<br/>
            {selectedTask.exec_time?.retries?.count &&
              <>
                <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('SCANNING_PARTS', 0, 1)}
              </>
            }
          </div><hr/>
          <div>
            <b>{t('PlannerTask')}:</b> {getStatus(0, 'planner_status')}<br/>
            <b>{t('ProcessingTime')}:</b> {getTimeDiff('PLANNER_TASK', 0)}<br/>
            {selectedTask.exec_time?.retries?.count &&
              <>
              <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('PLANNER_TASK', 0, 1)}
              </>
            }
          </div><hr/>
          <div>
            <b>{t('ExecutorTask')}:</b> {getStatus(0, 'executor_status')}<br/>
            <b>{t('ProcessingTime')}:</b> {getTimeDiff('EXECUTOR_TASK', 0)}<br/>
            {selectedTask.exec_time?.retries?.count &&
              <>
                <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('EXECUTOR_TASK', 0, 1)}
              </>
            }
          </div><hr/>
          {selectedTask['exec_time'] && selectedTask['exec_time'][1] &&
            <>
              <div>{t('AfterTurn')}:</div><hr/>
              <div>
                <b>{t('BeamScan')}:</b> {getStatus(1, 'scanning_beam_status')}<br/>
                <b>{t('ProcessingTime')}:</b> {getTimeDiff('SCANNING_BEAM', 1)}<br/>
                {selectedTask.exec_time?.retries?.count &&
                  <>
                    <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                    <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('SCANNING_BEAM', 1, 1)}
                  </>
                }
              </div><hr/>
              <div>
                <b>{t('PlannerTask')}:</b> {getStatus(1, 'planner_status')}<br/>
                <b>{t('ProcessingTime')}:</b> {getTimeDiff('PLANNER_TASK', 1)}<br/>
                {selectedTask.exec_time?.retries?.count &&
                  <>
                    <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                    <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('PLANNER_TASK', 1, 1)}
                  </>
                }
              </div><hr/>
              <div>
                <b>{t('ExecutorTask')}:</b> {getStatus(1, 'executor_status')}<br/>
                <b>{t('ProcessingTime')}:</b> {getTimeDiff('EXECUTOR_TASK', 1)}<br/>
                {selectedTask.exec_time?.retries?.count &&
                  <>
                    <b>{t('RetryCounts')}:</b> {selectedTask.exec_time.retries.count}<br/>
                    <b>{t('ProcessingTimeRetry')}:</b> {getTimeDiff('EXECUTOR_TASK', 1, 1)}
                  </>
                }
              </div>
            </>
          }
          {archiveModelIsLoading ?
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px'}}>
             <CircularProgress size={40} style={{color: 'white'}}/></div> :
           <Viewer3DContainer selectedModel={selectedModel} missedPartsList={missedPartsList} isMobile={false}
                              height={'50%'}/>}
          <SchemeModal open={open} handleClose={handleClose} selectedModel={selectedModel}/>
        </>
      }
  </StyledTaskView>
}

export default SelectedTaskView;
