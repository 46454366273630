import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, TableHead, Typography} from "@mui/material";

import useStyles from "../../components/Tables/GeneralTable/GeneralTableStyle";
import {useTranslation} from "react-i18next";
import {StyledGeneralTable} from "../../components/Tables/GeneralTable/StyledGeneralTable";


export default function GeneralTable({   isMobile,
                                       headers = [],
                                       rows = [],
                                       onRowClick = null,
                                       activeItem = null,
                                       tableIsLoading = false
                                     }) {

  const classes = useStyles()
  const {t} = useTranslation();
  return (
    <StyledGeneralTable>
      <TableContainer component={Paper}
                      style={{height: isMobile ? '100%' : '81vh', borderRadius: '5px', backgroundColor: '#141414'}}>
        <Table className={classes.mainTable} stickyHeader>
          <TableHead style={{height: '72px', borderRadius: '5px', backgroundColor: '#141414'}}>
            <TableRow style={{backgroundColor: '#1F1F1F !important', borderBottom: '2px solid #141414'}}>
              {headers.map((item, index) => {
                return <TableCell key={item} colSpan={index === 4 ? 2: 1} className={'header_cells'}
                                  style={ { backgroundColor: '#1F1F1F', borderBottom:'none',
                                    width: index === 0 ? '97px' : index === 1 ? '220px' : 'initial',
                                  }}>
                  <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                    <span dangerouslySetInnerHTML={{__html: t(item)}}/>
                  </Typography>
                </TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableIsLoading ? <TableRow style={{height: '70vh'}}>
                              <TableCell colSpan={5} style={{border: 'none'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                  <CircularProgress color={'secondary'} size={50}/></div>
                              </TableCell>
                            </TableRow> :


             rows.map((row, index) => (
               <TableRow key={index}
                         className={index === activeItem ? classes.tableBodyRowSelected : classes.tableBodyRow}>
                 {Object.values(row).map((item, ind) => {
                   return <TableCell key={ind} align="left" style={{
                     borderBottom: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                     borderTop: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                     backgroundColor: index !== activeItem ? "initial" : "#303030",
                     color: '#EFF3F9',
                     fontSize: isMobile ? '12px' : '18px',
                     width: ind === 1 ? '275px' : 'initial',
                     wordBreak: 'break-all'
                   }}
                                     onClick={ind !== 5 && onRowClick ? () => {
                                       onRowClick(row.name, index, row.count)
                                     } : null}
                   >
                     {item}
                   </TableCell>
                 })}
               </TableRow>
             ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGeneralTable>
  );
}
