import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
	cancelProcessingTaskRequest,
	resetPartsCounter,
} from "../../../redux/reducers/statusReducer/actions";

import {SocketContext} from "../../../redux/customReducer/SocketContext";
import StyledCheckbox from "../../StyledInputs/StyledCheckbox";
import TableInput from "./TableInput";
import ServiceButton from "../../ServiceButton/ServiceButton";
import check_role from "../../../utils/utils";
import i_beam from "../../../assets/images/i_beam.jpg";
import h_beam from "../../../assets/images/h_beam.jpg";
import ExcludePartsModal from "../../../pages/StatusMainPage/ExcludePartsModal/ExcludePartsModal";


function StatusStartSettings() {


	const {
		state: {ws},
	} = useContext(SocketContext);
	const dispatch = useDispatch()

	const {
		beam_stand,
		taskId,
		beamPosition,
		tableLength,
		processingTaskIsCanceling,
		selectedModel,
		parts_to_skip
	} = useSelector(
		(state) => state.statusReducer)
	const {username} = useSelector((state) => state.cachedReducer)
	const [checks, setChecks] = useState({
											 racksAreEstablished: false,
											 beamIsEstablished: false,
											 detailsAreEstablished: false
										 })
	const [tableIsOk, setTableIsOk] = useState(true)
	const [excludePartsModal, setExcludePartsModal] = useState(false)

	const labelsCheckbox = ['racksAreEstablished', 'beamIsEstablished', 'detailsAreEstablished']
	const {t} = useTranslation()

	const onCheck = (name, checked) => {
		setChecks({...checks, [name]: checked})
	}

	const get_beam_picture = () => {
		switch (beam_stand){
			case 90:
			case 270:
				return <img alt="" src={h_beam} style={{width: '50%', borderRadius: '10px'}}/>
			case 0:
			case 180:
			default:
				return <img alt="" src={i_beam} style={{width: '50%', borderRadius: '10px'}}/>
		}
	}

	return (
		<>
			<Box style={{backgroundColor: '#141414', borderRadius: '8px', height: '100%'}}>
				<div style={{width: '100%', height: '90%', display: 'flex'}}>
					<div style={{width: 'calc(50% - 30px)', height: 'calc(100% - 30px)', paddingLeft: '30px', paddingTop: '30px', border: 'solid 1px white'}}>
						<Typography color={'white'} style={{fontSize: '1.7vh'}}>  {t('StatusSettings')}</Typography>
						<div style={{marginLeft: '-10px'}}>
							{labelsCheckbox.map((item) => {
								return <div key={item} style={{display: 'flex', alignItems: 'center'}}>
									<StyledCheckbox checked={checks[item]}
													onChange={(event, checked) => {
														onCheck(item, checked)
													}}/>
									<Typography color={checks[item] ? '#54fcef' : 'white'}
												fontSize={'1.6vh'}>{t(item)}</Typography>
								</div>
							})}
						</div>

						<Typography color={'white'}
									style={{
										fontSize: '1.7vh',
										marginBottom: '4px'
									}}>{t('TableStatusInput')}</Typography>
						<div style={{maxWidth: "700px"}}>
							<TableInput setTableIsOk={setTableIsOk}/>
						</div>
						{!tableIsOk ? <Typography style={{fontSize: '1.5vh', flex: 1}}
												  color={'yellow'}> {t('TableWarning')} </Typography> : null}
						<ExcludePartsModal open={excludePartsModal} handleClose={() => {
							setExcludePartsModal(false)
						}} selectedModel={selectedModel} parts_to_skip={parts_to_skip}/>
						<hr/>
						<Button variant={'contained'} color={'secondary'} onClick={() => {
							setExcludePartsModal(true)
						}}
								style={{marginTop: '10px'}}>{t('ExcludeParts')}</Button>
						{parts_to_skip.length > 0 &&
							<table>
								<tr>
									<td style={{color: 'white', width: '100px'}}>{t('excluded_parts')}:</td>
									<td><div style={{color: 'white', padding: '15px', wordBreak: 'break-all'}}>
										{parts_to_skip.map((item, index) => {
											return <span key={index} style={{color: 'white', padding: '0px 5px'}}>{item}</span>
										})}
									</div></td>
								</tr>
							</table>
						}
					</div>
					<div style={{width: 'calc(50% - 30px)', height: 'calc(100% - 30px)', paddingLeft: '30px', paddingTop: '30px', border: 'solid 1px white'}}>
						<div style={{height: "100%", width: "100%", textAlign: 'center'}}>
							<Typography color={'white'}
										style={{
											fontSize: '1.7vh',
											marginBottom: '4px',
											textTransform: 'uppercase'
										}}>{t('RackPosition')}:</Typography>
							{get_beam_picture()}
						</div>
					</div>
				</div>
				<div style={{width: 'calc(100% - 30px)', height: 'calc(10% - 2px)', alignItems: 'center', justifyContent: 'left', display: 'flex', paddingLeft: '30px', border: 'solid 1px white'}}>
					{check_role(username) &&
					 <div style={{display: 'flex'}}>
						 <Button variant={'contained'} color={'secondary'}
								 disabled={
									 !(
					 checks.racksAreEstablished && checks.beamIsEstablished && checks.detailsAreEstablished
					 && tableLength !== 0 && tableIsOk
									 )
								 }
								 onClick={() => {
									 dispatch(resetPartsCounter())
									 ws.emit('start_assembling', {taskId, beamPosition, tableLength, parts_to_skip})
								 }}
								 style={{width: '139px'}}>  {t('LaunchButton')} </Button>
						 <div style={{marginLeft: '20px'}}>
							 <ServiceButton
								 color={'error'}
								 width={115}
								 variant={'contained'}
								 buttonName={'DeleteProcessingTask'}
								 loadingCondition={processingTaskIsCanceling}
								 onClick={() => {
									 dispatch(cancelProcessingTaskRequest(taskId))
								 }}/>
						 </div>
					 </div>
					}
				</div>
			</Box>
		</>
	);
}

export default StatusStartSettings;
