import React, {useState} from 'react';
import MeshMaterial from "../../../MeshMaterial/MeshMaterial";
import {addSkipPart, deleteSkipPart} from "../../../../../redux/reducers/statusReducer/actions";
import {useDispatch} from "react-redux";


function SingleModelPart({geometry, viewerSettings, skip_parts}) {

    const {parts, missedParts} = viewerSettings
    const settings = geometry.type === 'missedPart' ? missedParts : parts
    const [color, setColor] = useState(settings.color)
    const [apply_matrix, setApply_matrix] = useState(false)
    const dispatch = useDispatch()

    const getMesh = (mesh) => {
        if (mesh && !apply_matrix) {
            mesh.applyMatrix4(geometry.matrix)
            setApply_matrix(true)
        }
    }

    const remove_part = (e) => {
        if(!geometry.isBeam){
            if(color === missedParts.color){
                setColor(parts.color)
                dispatch(deleteSkipPart(geometry.id))
            }else{
                setColor(missedParts.color)
                dispatch(addSkipPart(geometry.id))
            }
        }
        e.stopPropagation()
    }

    return <mesh
        onClick={skip_parts ? (e) => {remove_part(e)} : false}
        ref={getMesh}
        castShadow={true}
        receiveShadow={false}
        position={geometry.position}
        geometry={geometry.geometry} scale={1}>

        <MeshMaterial
            type={settings.materialType}
            attach="material"
            color={color}
            transparent={true}
            opacity={settings.opacity}
            wireframe={settings.wireframe}
        />
    </mesh>
}

export default SingleModelPart;
