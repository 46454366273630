import {useSelector} from "react-redux";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import {
  MATCHING_LESS_PARTS_ERROR, MATCHING_PARTS_CONFIRMED,
  MATCHING_PARTS_ERROR,
  MATCHING_PARTS_IS_RUNNING,
  MATCHING_PARTS_SUCCESS, MATCHING_PARTS_TIMEOUT_ERROR,
  MATCHING_REVERSE_PARTS_ERROR,
  MATCHING_UNMATCHED_PARTS_ERROR,
} from "../statusConstants";
import React from "react";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import PartsViewer from "../../../pages/PartsViewer/PartsViewer";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";


const MatchingPartsComponent = ({screenVersion = false, isMobile}) => {
  const {matchingPartsStatus} = useSelector(state => state.statusReducer)

  const statusRender = (status) => {
    switch (status) {
      case MATCHING_PARTS_CONFIRMED:
        return <StatusInfo status={status} isMobile={isMobile}/>
      case MATCHING_PARTS_IS_RUNNING:
        return !screenVersion ? <ProcessAction status={MATCHING_PARTS_IS_RUNNING}/> :
          <ProcessingActionScreen status={MATCHING_PARTS_IS_RUNNING}/>
      case MATCHING_PARTS_SUCCESS:
        return <PartsViewer/>
      case MATCHING_PARTS_ERROR:
      case MATCHING_PARTS_TIMEOUT_ERROR:
      case MATCHING_LESS_PARTS_ERROR:
        return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
      case MATCHING_UNMATCHED_PARTS_ERROR:
      case MATCHING_REVERSE_PARTS_ERROR:
        return <PartsViewer error={status}/>
      default:
        return <UnknownStatus isMobile={isMobile}/>
    }
  }
  return statusRender(matchingPartsStatus)
};

export default MatchingPartsComponent;
