import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import {useTranslation} from "react-i18next";

function Parts({simulation}){
	const {t} = useTranslation();

	return <TableContainer component={Paper} style={{overflow: 'scroll', height: '100%'}}>
		<Table sx={{ minWidth: 250 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell align="left"><b>{t('Part')}</b></TableCell>
					<TableCell align="left"><b>{t('Quantity')}</b></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{simulation && simulation['parts'] && Object.entries(simulation['parts']).map(([k,v]) =>
				  <TableRow
					  key={k}
					  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				  >
					  <TableCell align="left">{k}</TableCell>
					  <TableCell align="left">{v}</TableCell>
				  </TableRow>
				)}
			</TableBody>
		</Table>
	</TableContainer>
}
export default Parts;