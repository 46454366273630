import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {cancelSimulation, deleteModelRequest, startPreAssembly} from "../../../redux/reducers/modelsReducer/actions";
import ServiceButton from "../../ServiceButton/ServiceButton";
import check_role from "../../../utils/utils";


function ModelsButtons({isMobile, filter, handleOpen}) {

    const {
        modelsButtonsState,
        modelInstance,
        modelIsDeleting,
        modelIsCanceling,
    } = useSelector((state) => state.modelsReducer)
    const {username} = useSelector((state) => state.cachedReducer)
    const dispatch = useDispatch()

    const buttonFunctions = {
        showModelInfoButton: <ServiceButton variant={'contained'}
                                          color={'secondary'}
                                          width={113}
                                          buttonName={'openModelInfo'}
          // loadingCondition={updatingModelStatus}
                                          onClick={() => {handleOpen()}}
                                          style={{marginLeft: '20px', lineHeight: '1.3'}}/>,
        showSimulationBtn: <ServiceButton variant={'contained'}
                                          color={'secondary'}
                                          width={145}
                                          buttonName={'SimulateButton'}
                                          // loadingCondition={updatingModelStatus}
                                          onClick={() => dispatch(startPreAssembly(modelInstance.name, filter))}
                                          style={{marginLeft: '20px', lineHeight: '1.3',
                                              display: check_role(username) ? 'inline-flex' : 'none'}}/>,
        showReSimulationBtn: <ServiceButton variant={'contained'}
                                          color={'secondary'}
                                          width={152}
                                          buttonName={'ReSimulateButton'}
            // loadingCondition={updatingModelStatus}
                                          onClick={() => dispatch(startPreAssembly(modelInstance.name, filter))}
                                          style={{marginLeft: '20px', lineHeight: '1.3',
                                              display: check_role(username) ? 'inline-flex' : 'none'}}/>,
        showCancelSimulation: <ServiceButton variant={'contained'}
                                            color={'error'}
                                            width={152}
                                            buttonName={'CancelSimulation'}
                                            loadingCondition={modelIsCanceling}
                                            onClick={() => dispatch(cancelSimulation(modelInstance.name))}
                                            style={{marginLeft: '20px', lineHeight: '1.3',
                                                display: check_role(username) ? 'inline-flex' : 'none'}}/>,
        showSpinnerBtn: null,

        // showSaveTaskBtn: <ServiceButton variant={'contained'}
        //                                 color={'secondary'}
        //                                 width={250}
        //                                 buttonName={'SubmitTaskButton'}
        //                                 loadingCondition={updatingModelStatus}
        //                                 onClick={() => dispatch(updateSimulationTaskStatus(modelInstance.name))}
        //                                 style={{marginLeft: '20px'}}
        // />,
        showSaveTaskBtn: null,


        showDeleteBtn: <ServiceButton variant={'contained'}
                                      color={'error'}
                                      width={isMobile ? 130 : 126}
                                      height={isMobile ? 30 : 40}
                                      buttonName={'DeleteButton'}
                                      loadingCondition={modelIsDeleting}
                                      onClick={() => dispatch(deleteModelRequest(modelInstance.name))}
                                      style={{marginLeft: '20px', lineHeight: '1.3',
                                          display: check_role(username) ? 'inline-flex' : 'none'}}/>,
    }


    return (
        <div>


            {Object.entries(modelsButtonsState).map(([key, value]) => {
                if (value) {
                    return <Fragment key={key}>{buttonFunctions[key]}</Fragment>
                } else {
                    return <Fragment key={key}/>
                }
            })}
        </div>
    );
}

export default ModelsButtons;
