import React from 'react';
import Box from "@mui/material/Box";
import {StyledDebug} from "./StyledDebug";
import {useTranslation} from "react-i18next";
import Planner from "./Planner/Planner";
import Executor from "./Executor/Executor";


function Debug() {
  const {t} = useTranslation()

  return (
    <StyledDebug>
      <Box className={"main_box"}>
        <div className={"planner_div"}>
          <Planner/>
        </div>
        <div style={{height: '100%'}}>
          <Executor/>
        </div>
      </Box>
    </StyledDebug>
  );
}

export default Debug;
