import styled from 'styled-components';

export const StyledGeneralTable = styled.div`
	.header_cells{
	  background-color: #1F1F1F;
	  border-bottom: none;
	}
	.MuiIconButton-root{
	  padding: 0;
	}
	.MuiTableCell-root{
	  padding: 10px;
	}
`;
