export const CALCULATE_INITIAL_TASK_DATA = 'CALCULATE_INITIAL_TASK_DATA'
export const CHECK_NEED_TURN = 'CHECK_NEED_TURN'
export const SET_INITIAL_TASK_DATA = 'SET_INITIAL_TASK_DATA'
export const SET_TASK_STATUS = 'SET_TASK_STATUS'
export const SET_TABLE_LENGTH = 'SET_TABLE_LENGTH'


export const CANCEL_PROCESSING_TASK_REQUEST = 'CANCEL_PROCESSING_TASK_REQUEST'
export const CANCEL_PROCESSING_TASK_SUCCESS = 'CANCEL_PROCESSING_TASK_SUCCESS'
export const CANCEL_PROCESSING_TASK_FAIL = 'CANCEL_PROCESSING_TASK_FAIL'

export const INITIALIZE_STATUS_PAGE = 'INITIALIZE_STATUS_PAGE'
export const DEINITIALIZE_STATUS_PAGE = 'DEINITIALIZE_STATUS_PAGE'


export const SET_PLANNER_STATUS = 'SET_PLANNER_STATUS'
export const SET_EXECUTOR_STATUS = 'SET_EXECUTOR_STATUS'
export const SET_LABELING_STATUS = 'SET_LABELING_STATUS'
export const SET_SCANNING_BEAM_STATUS = 'SET_SCANNING_BEAM_STATUS'
export const SET_SCANNING_PARTS_STATUS = 'SET_SCANNING_PARTS_STATUS'

export const SET_ASSEMBLED_PARTS = 'SET_ASSEMBLED_PARTS'
export const SET_PLANNED_PARTS = 'SET_PLANNED_PARTS'
export const RESET_PARTS_COUNTER = 'RESET_PARTS_COUNTER'
export const ADD_SKIP_PART = 'ADD_SKIP_PART'
export const DELETE_SKIP_PART = 'DELETE_SKIP_PART'
export const SELECT_ALL_PARTS = 'SELECT_ALL_PARTS'
export const SET_STATUSES = 'SET_STATUSES'
