import {
    CANCEL_PROCESSING_TASK_FAIL,
    DEINITIALIZE_STATUS_PAGE,
    CANCEL_PROCESSING_TASK_REQUEST,
    CANCEL_PROCESSING_TASK_SUCCESS,
    INITIALIZE_STATUS_PAGE, RESET_PARTS_COUNTER, SET_ASSEMBLED_PARTS,
    SET_EXECUTOR_STATUS,
    SET_INITIAL_TASK_DATA,
    SET_LABELING_STATUS, SET_PLANNED_PARTS,
    SET_PLANNER_STATUS,
    SET_SCANNING_BEAM_STATUS,
    SET_SCANNING_PARTS_STATUS,
    SET_STATUSES,
    SET_TABLE_LENGTH,
    SET_TASK_STATUS, CHECK_NEED_TURN, ADD_SKIP_PART, DELETE_SKIP_PART, SELECT_ALL_PARTS
} from "./types";



export const checkNeedTurn = (task_id, handleOpen) => ({
    type: CHECK_NEED_TURN, task_id, handleOpen
})
export const setInitialTaskData = (data) => ({
    type: SET_INITIAL_TASK_DATA, payload: data
})

export const setTaskStatus = (status) => ({
    type: SET_TASK_STATUS, payload: status
})

export const setStatuses = (statuses)  => ({
    type: SET_STATUSES, payload: statuses
})


export const setTableLength = (tableLength) => ({
    type: SET_TABLE_LENGTH, payload: tableLength
})


export const cancelProcessingTaskRequest = (taskId) => ({
    type: CANCEL_PROCESSING_TASK_REQUEST, payload: taskId
})
export const cancelProcessingTaskSuccess = () => ({
    type: CANCEL_PROCESSING_TASK_SUCCESS
})
export const cancelProcessingTaskFail = () => ({
    type: CANCEL_PROCESSING_TASK_FAIL
})

export const initializeStatusPage = () => ({
    type: INITIALIZE_STATUS_PAGE
})
export const deinitializeStatusPage = () => ({
    type: DEINITIALIZE_STATUS_PAGE
})


export const setLabelingStatus = (status) => ({
    type: SET_LABELING_STATUS, payload: status
})
export const setExecutorStatus = (status) => ({
    type: SET_EXECUTOR_STATUS, payload: status
})
export const setPlannerStatus = (status) => ({
    type: SET_PLANNER_STATUS, payload: status
})

export const setScanningBeamStatus = (status) => ({
    type: SET_SCANNING_BEAM_STATUS, payload: status
})
export const setScanningPartsStatus = (status) => ({
    type: SET_SCANNING_PARTS_STATUS, payload: status
})

export const setAssembledParts = (data) => ({
    type: SET_ASSEMBLED_PARTS, payload: data
})
export const setPlannedParts = (data) => ({
    type: SET_PLANNED_PARTS, payload: data
})
export const resetPartsCounter = () => ({
    type: RESET_PARTS_COUNTER
})

export const addSkipPart = (data) => ({
    type: ADD_SKIP_PART, payload: data
})

export const deleteSkipPart = (data) => ({
    type: DELETE_SKIP_PART, payload: data
})

export const selectAllParts = (data) => ({
    type: SELECT_ALL_PARTS, payload: data
})
