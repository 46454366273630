export const GETTING_INITIAL_INFORMATION = 'GETTING_INITIAL_INFORMATION'
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR'
export const IDLE = 'IDLE'

// taskStatus
export const MOUNTING = 'MOUNTING'
export const SUCCESSFULLY_ENDED = 'SUCCESSFULLY_ENDED'
export const SCANNING_BEAM_ACTION = 'SCANNING_BEAM_ACTION'
export const MATCHING_BEAM_ACTION = 'MATCHING_BEAM_ACTION'
export const SCANNING_PARTS_ACTION = 'SCANNING_PARTS_ACTION'
export const MATCHING_PARTS_ACTION = 'MATCHING_PARTS_ACTION'
export const LABELING_AND_PLANNING_ACTION = 'LABELING_AND_PLANNING_ACTION'
export const EXECUTOR_AND_PLANNING_ACTION = 'EXECUTOR_AND_PLANNING_ACTION'
export const TURN_BEAM = 'TURN_BEAM'

//scanningBeamStatus
export const SCANNING_BEAM_IS_RUNNING = 'SCANNING_BEAM_IS_RUNNING'
export const SCANNING_BEAM_SUCCESS = 'SCANNING_BEAM_SUCCESS'
export const SCANNING_BEAM_ERROR = 'SCANNING_BEAM_ERROR'
export const SCANNING_BEAM_ROBOT_ERROR = 'SCANNING_BEAM_ROBOT_ERROR'

//matchingBeamStatus
export const MATCHING_BEAM_IS_RUNNING = 'MATCHING_BEAM_IS_RUNNING'
export const MATCHING_BEAM_SUCCESS = 'MATCHING_BEAM_SUCCESS'
export const MATCHING_BEAM_ERROR = 'MATCHING_BEAM_ERROR'
export const MATCHING_BEAM_CONFIRMED = 'MATCHING_BEAM_CONFIRMED'

//scanningPartsStatus
export const SCANNING_PARTS_IS_RUNNING = 'SCANNING_PARTS_IS_RUNNING'
export const SCANNING_PARTS_SUCCESS = 'SCANNING_PARTS_SUCCESS'
export const SCANNING_PARTS_ERROR = 'SCANNING_PARTS_ERROR'
export const SCANNING_PARTS_ROBOT_ERROR = 'SCANNING_PARTS_ROBOT_ERROR'

//matchingBeamStatus
export const MATCHING_PARTS_IS_RUNNING = 'MATCHING_PARTS_IS_RUNNING'
export const MATCHING_PARTS_SUCCESS = 'MATCHING_PARTS_SUCCESS'
export const MATCHING_PARTS_ERROR = 'MATCHING_PARTS_ERROR'
export const MATCHING_REVERSE_PARTS_ERROR = 'MATCHING_REVERSE_PARTS_ERROR'
export const MATCHING_LESS_PARTS_ERROR = 'MATCHING_LESS_PARTS_ERROR'
export const MATCHING_UNMATCHED_PARTS_ERROR = 'MATCHING_UNMATCHED_PARTS_ERROR'
export const MATCHING_PARTS_CONFIRMED = 'MATCHING_PARTS_CONFIRMED'

//labelingStatus
export const LABELING_IS_RUNNING = 'LABELING_IS_RUNNING'
export const LABELING_SUCCESS = 'LABELING_SUCCESS'
export const LABELING_ERROR = 'LABELING_ERROR'
export const LABELING_ROBOT_ERROR = 'LABELING_ROBOT_ERROR'

//executorStatus
export const EXECUTOR_IS_RUNNING = 'EXECUTOR_IS_RUNNING'
export const EXECUTOR_SUCCESS = 'EXECUTOR_SUCCESS'
export const EXECUTOR_ERROR = 'EXECUTOR_ERROR'
export const EXECUTOR_ROBOT_ERROR = 'EXECUTOR_ROBOT_ERROR'

//plannerStatus
export const PLANNER_IS_RUNNING = 'PLANNER_IS_RUNNING'
export const PLANNER_SUCCESS = 'PLANNER_SUCCESS'
export const PLANNER_ERROR = 'PLANNER_ERROR'
export const SCANNING_BEAM_TIMEOUT_ERROR = 'SCANNING_BEAM_TIMEOUT_ERROR'
export const MATCHING_BEAM_TIMEOUT_ERROR = 'MATCHING_BEAM_TIMEOUT_ERROR'
export const SCANNING_PARTS_TIMEOUT_ERROR = 'SCANNING_PARTS_TIMEOUT_ERROR'
export const MATCHING_PARTS_TIMEOUT_ERROR = 'MATCHING_PARTS_TIMEOUT_ERROR'
export const LABELING_TIMEOUT_ERROR = 'LABELING_TIMEOUT_ERROR'
export const EXECUTOR_TIMEOUT_ERROR = 'EXECUTOR_TIMEOUT_ERROR'
export const PLANNER_TIMEOUT_ERROR = 'PLANNER_TIMEOUT_ERROR'



export const IDLE_STATUSES = {
    statuses: {
        taskStatus: IDLE,
        beam_action: IDLE,
        parts_action: IDLE,
        scanningBeamStatus: IDLE,
        matchingBeamStatus: IDLE,
        scanningPartsStatus: IDLE,
        matchingPartsStatus: IDLE,
        labelingStatus: IDLE,
        executorStatus: IDLE,
        plannerStatus: IDLE,
    }
}
