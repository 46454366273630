import styled from 'styled-components';

export const StyledModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
	transform: translate(-50%, -50%);
    width: 600px;
    height: 50vh;
    bgcolor: background.paper;
    border: 2px solid #000;
    box-shadow: 0 5px 15px rgba(0,0,0,0);
    padding: 2px;
	background-color: white;
	.status_tab{
		border: 1px solid white;
	}
	.MuiTableCell-root{
		text-align: center;
	}
`;
