import {useContext, useEffect} from 'react';
import {SocketContext} from "../redux/customReducer/SocketContext";
import io from "socket.io-client";
import {useDispatch} from "react-redux";
import {
    initializeStatusPage, setAssembledParts,
    setExecutorStatus,
    setInitialTaskData,
    setLabelingStatus, setPlannedParts,
    setPlannerStatus,
    setStatuses,
    setTaskStatus
} from "../redux/reducers/statusReducer/actions";
import {SET_WS} from "../redux/customReducer/types";
import {SOCKET_CONNECTION_ERROR} from "../components/StatusesComponets/statusConstants";
import {sendGeneralMessage} from "../redux/reducers/layoutReducer/actions";
import {setRobotStatuses, setRobotStatusesReady} from "../redux/reducers/robotStatusReducer/actions";
import {useLocation} from "react-router-dom";
import i18n from "../i18n";
import {getSimulationTaskRequest} from "../redux/reducers/modelsReducer/actions";
import useTableFilter from "../utils/useTableFilter";

// const ws = io.connect(process.env.REACT_APP_SOCKET_URI)
const ws = io.connect(process.env.REACT_APP_SOCKET_URI, {path: "/ws/socket.io", transports: ['websocket', 'polling']})

function WebSocketSettings() {
    const {update} = useContext(SocketContext);
    const dispatch = useDispatch()
    const location = useLocation()
    const loadData = (filter) => dispatch(getSimulationTaskRequest(filter));
    const {reloadPage} = useTableFilter(loadData, {
        sortField: 'sort_date',
        sortOrder: 'desc',
        pageNumber: 1,
        pageSize: 20
    });
    const dispatchStatuses = (statuses) => {
        const {
            task_status,
            beam_action,
            parts_action,
            scanning_beam_status,
            matching_beam_status,
            scanning_parts_status,
            matching_parts_status,
            labeling_status,
            executor_status,
            planner_status
        } = statuses

        dispatch(setStatuses({
            statuses: {
                taskStatus: task_status,
                beam_action: beam_action,
                parts_action: parts_action,
                scanningBeamStatus: scanning_beam_status,
                matchingBeamStatus: matching_beam_status,
                scanningPartsStatus: scanning_parts_status,
                matchingPartsStatus: matching_parts_status,
                labelingStatus: labeling_status,
                executorStatus: executor_status,
                plannerStatus: planner_status,
            }
        }))
    }

    const initiatingData = ({data, statuses}) => {
        if (data && statuses) {

            let {
                beam_stand,
                beamPosition,
                plannerStatus,
                labelStatus,
                executorStatus,
                beamTransform,
                taskId,
                assemblyName,
                selectedModel,
                scannedParts,
                rackParts,
                inspection,
                parts_statuses,
            } = data

            if (!data.rackParts) {
                data.rackParts = {
                    holder3_is_used: true,
                    beam_offset: 0,
                    holder1_position: 0,
                    holder2_position: 0,
                    holder3_position: 0,
                    holder1_offset: 0,
                    holder2_offset: 0,
                    holder3_offset: 0
                }
            }
            dispatch((setInitialTaskData({
                beam_stand,
                beamPosition,
                beamTransform,
                taskId,
                plannerStatus,
                labelStatus,
                executorStatus,
                assemblyName,
                selectedModel,
                scannedParts,
                rackParts,
                inspection,
                parts_statuses
            })))

            dispatchStatuses(statuses)
        }
    }


    useEffect(() => {
        ws.on("connect_error", () => {
            dispatch(setTaskStatus(SOCKET_CONNECTION_ERROR))
        });

        ws.on('send_initial_data', initiatingData)

        ws.on('submitted_task_mounting', initiatingData)

        ws.on('send_status', (statuses) => {
            dispatchStatuses(statuses)
        })

        ws.on('assembled_parts_counter', (data) => {
            dispatch(setAssembledParts(data))
        })
        ws.on('planned_parts_counter', (data) => {
            dispatch(setPlannedParts(data))
        })

        ws.on('send_labeler_status', (status) => {

            dispatch(setLabelingStatus(status))
        })
        ws.on('send_executor_status', (status) => {
            dispatch(setExecutorStatus(status))
        })
        ws.on('send_planner_status', (status) => {
            dispatch(setPlannerStatus(status))
        })


        ws.on('server_error', (message) => {
            let mes = JSON.stringify(message?.message)
            console.log(mes)
            dispatch(sendGeneralMessage(`${i18n.t('server_error')} : ${mes}`, 'error'))
        })
        ws.on('reload_page', () => {
            // "/" is models page
            if(location.pathname === "/"){
                reloadPage()
            }
        })

        ws.on('robot_statuses', (robotStatuses) => {

            const [IRB2600, IRB6700] = robotStatuses
            if(!robotStatuses.length){
                return
            }
            dispatch(setRobotStatuses({
                'IRB2600': {Motors: IRB2600.motors , Server: IRB2600.server, Logger: IRB2600.logger},
                'IRB6700': {Motors: IRB6700.motors, Server: IRB6700.server, Logger: IRB6700.logger},
            }))
            dispatch(setRobotStatusesReady())
        })


        update({type: SET_WS, payload: ws})

        ws.emit('request_initial_data')
        // ws.emit('request_initial_data', {task_id: '62294528-a363-45b1-b9ae-84a11e0817fa'})
        dispatch(initializeStatusPage())
    }, [ws])

    return null
}

export default WebSocketSettings;
