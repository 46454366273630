import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import {useTranslation} from "react-i18next";

function Info({simulation}){
	const {t} = useTranslation();

	return <TableContainer component={Paper} style={{overflow: 'scroll', height: '100%'}}>
		<Table sx={{ minWidth: 250 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell align="left"><b>{t('Position')}</b></TableCell>
					<TableCell align="left"><b>{t('Beam_stand')}</b></TableCell>
					<TableCell align="left"><b>{t('Shift')}</b></TableCell>
					<TableCell align="left"><b>{t('tobe_assembled_parts')}</b></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{simulation && simulation['planning_info'] && simulation['planning_info']['assembly_info']
				 && Object.entries(simulation['planning_info']['assembly_info']).map(([k,v]) =>
					  <TableRow
						  key={k}
						  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					  >
						  <TableCell align="left">{k}</TableCell>
						  <TableCell align="left">{v['beam_stand']}</TableCell>
						  <TableCell align="left">{v['shift']}</TableCell>
						  <TableCell align="left">{Object.entries(v['list_of_assembled_parts']).map(([key,values]) =>
							  <>{values}<br/></>)
						  }</TableCell>
					  </TableRow>
				)}
			</TableBody>
		</Table>
	</TableContainer>
}
export default Info;